import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react"

import Home from './pages/Home'
import NumbersList from './pages/NumbersList'

import './App.css'

const App = () => (
 <Router>
    <ChakraProvider>
      <main className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/numberslist">
            <NumbersList />
          </Route>
        </Switch>
      </main>
    </ChakraProvider>
  </Router>
)

export default App
