import React from 'react';

const Home = () => {
  return (
    <div className="homePage">
      oi
    </div>
  )
}
 
export default Home;