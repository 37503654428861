import React, { useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react"

import './styles.css'

const NumberItem = ({number, name, availability}) => {
  const [personName, setPersonName] = useState("")
  const [email, setEmail] = useState("")
  const isAvailable = availability ? "Disponível" : "Reservado"
  const availabilityTagColor = availability ? "availableTag" : "reservedTag"

  const { isOpen, onClose, onOpen } = useDisclosure()

  function messageConstructor() {
    let message = personName + "%20" + email
    return message
  }

  function openWhatsapp() {
    window.location.href = `https://api.whatsapp.com/send?phone=5511961666340&text=${messageConstructor()}`
  }

  return (
    <>
    <div className="numberItemStyles">
      <strong>{number}</strong>
      <span>{name}</span>
      <button onClick={onOpen} className={availabilityTagColor}>
        {isAvailable}
      </button>
    </div>

    <Modal className="formModal" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
        <ModalContent>
          <div className="formModalContentWrapper">
            <ModalHeader>Você está comprando</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form className="formModalInputGroup">
                <Input className="formModalInput" variant="flushed" size="lg" placeholder="Nome" onChange={e => setPersonName(e.target.value)} />
                <Input className="formModalInput" variant="flushed" size="lg" placeholder="E-mail" onChange={e => setEmail(e.target.value)} />
              </form>
            </ModalBody>
            <Button variant="ghost" onClick={openWhatsapp}>CONFIRMAR COMPRA</Button>
          </div>
        </ModalContent>
    </Modal>
    </>
  )
}

export default NumberItem