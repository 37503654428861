import React from 'react'
import './styles.css'

import NumberItem from '../../containers/NumberItem'

const data = [
  {
    number: "001",
    name: "Canguru",
    availability: true
  },
  {
    number: "002",
    name: "Pavão",
    availability: false
  },
  {
    number: "003",
    name: "Urso Polar",
    availability: true
  },
  {
    number: "004",
    name: "Boi",
    availability: false
  },
  {
    number: "005",
    name: "Gato",
    availability: true
  },
  {
    number: "006",
    name: "Cachorro",
    availability: true
  },
  {
    number: "007",
    name: "Jacaré",
    availability: true
  },
  {
    number: "008",
    name: "Girafa",
    availability: true
  },
  {
    number: "009",
    name: "Capivara",
    availability: true
  },
  {
    number: "010",
    name: "Jumento",
    availability: false
  },
  {
    number: "011",
    name: "Dragão",
    availability: false
  },
  {
    number: "012",
    name: "Unicórnio",
    availability: true
  }
]

const NumberList = () => {
  return (
    <section className="numberListWrapper">
      {data.map((items, i) => {
        const { number, name, availability } = items
        return (
          <NumberItem key={i} number={number} name={name} availability={availability} />
        )
      })}
    </section>
  )
}
 
export default NumberList